.layout {
  background-color: #f2f2f2;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}

.header {
  display: block;
  height: 65px;

  .inner {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;

    .logo {
      font-size: 18px;
      font-weight: bold;
      display: flex;
      align-items: center;

      .title {
        margin-left: 8px;
        font-weight: bold;
      }
    }
  }
}

.content {
  // margin-top: 15px !important;
  // width: 1200px;
  // margin: auto;
  padding: 20px;
}

.footer {
  margin: auto;
  text-align: center;
}

/* antd css */
.ant-menu-item-icon {
  font-size: 18px !important;
}
.ant-menu-title-content {
  font-size: 16px;
}
