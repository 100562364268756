.result-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .result-card {
    width: 500px;
    height: 700px;
    background: #ffffff;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
    .top {
      height: 200px;
      background-color: #1777ee;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      .sroce-box {
        display: flex;
        margin-top: 53px;
        margin-left: 20px;
        .left {
          span:nth-child(1) {
            font-size: 80px;
          }
          span:nth-child(2) {
            font-size: 24px;
          }
        }
        .right {
          width: 140px;
          height: 60px;
          background-size: 100% 100%;
          font-size: 24px;
          font-weight: 500;
          color: #fff;
          span {
            display: block;
            margin-left: 21px;
            margin-top: 5px;
          }
        }
      }
    }
    .bottom {
      flex-grow: 1;
      padding: 20px 20px;
      .examName {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 52px;
      }
      .submitPaperTime {
        display: flex;
        align-items: center;
        margin-top: 10px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }
      .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item {
          div:nth-child(1) {
            margin-bottom: 12px;
            span:nth-child(1) {
              font-size: 32px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #444444;
            }
            span:nth-child(2) {
            }
          }
          div:nth-child(2) {
            span::before {
              content: '';
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              margin-right: 5px;
            }
          }
          .round-right::before {
            background-color: #66d39f;
          }
          .round-error::before {
            background-color: #ff8e80;
          }
          .round-empty::before {
            background-color: #cccccc;
          }
        }
      }
      .total {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #888888;
      }
    }
    .book-img {
      position: absolute;
      width: 200px;
      height: 132px;
      top: 95px;
      right: 10px;
    }
  }
}
.success-card .top .sroce-box .left {
  color: #53bf8c;
}
.fail-card .top .sroce-box .left {
  color: #ff7866;
}
