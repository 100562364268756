.do-answer-page-wrap {
  overflow: hidden;
  overflow-y: scroll;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  .do-answer-page {
    display: flex;
    justify-content: space-between;
  }
}
