.card-box {
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .title-box {
    width: 300px;
    height: 80px;
    background-size: 100%;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 38px;
    font-family: 228-SSGuangZheTi;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
