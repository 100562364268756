.do-exam-page-wrap {
  overflow: hidden;
  overflow-y: scroll;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.do-exam-page {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  .do-exam-page-title {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .time-box {
      width: 100px;
      height: 35px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
    }
  }
}
