.questree {
  // padding: 20px 0px 20px 0px;
  .flex {
    display: flex;
    align-items: center;
  }
  .userInfo {
  }
  .process-box {
    width: calc(100% - 20px);
    margin-bottom: 20px;
    margin-top: 10px;
    background-color: #edf0f6;
    border-radius: 5px;
    padding: 10px 10px;
    font-size: 14px;
    .line1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
  }
  .tags-box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .ant-tag {
      margin-right: 10px;
      &:nth-child(50) {
        margin-right: 0px;
      }
    }
  }
  .tags-title {
    width: calc(100% - 22px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    span:nth-child(1) {
      color: #2e3b76;
    }
    span:nth-child(2) {
      color: #a9aeb8;
    }
  }
}
