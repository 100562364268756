.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;

  .login-page-title {
    color: #fff;
    font-size: 42px;
    display: flex;
    align-items: center;
    margin-bottom: 82px;
    img {
      width: 40px;
      height: 42px;
      margin-right: 21px;
    }
  }
}
