@import '~antd/dist/antd.css';

body {
  margin: 0;
  background-color: #f2f2f2;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

th,
td {
  text-align: center !important;
}
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
// 侧边tree滚动条样式
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-track {
  border-radius: 0px;
  width: 0px; //背景条颜色无
}
::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 0px;
}
