.exam-item {
  border-radius: 16px;

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span:nth-child(1),
    span:nth-child(3) {
      color: #999;
    }
    span:nth-child(2) {
      width: 20px;
      border: 1px solid #ccc;
    }
  }
}
