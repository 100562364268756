.exam-cate-card {
  margin-bottom: 12px;
  cursor: pointer;
}
.exam-cate-item {
  display: flex;

  .info {
    margin-left: 20px;

    .title {
      font-size: 18px;
      font-weight: bold;
    }

    .sub-title {
      color: #999;
    }
  }
}
