.desc {
  margin-top: 20px;

  .row {
    padding: 6px;

    .key {
      font-size: 14px;
      color: #999;
    }

    .value {
      font-size: 16px;
      color: #000;
    }
  }
}
