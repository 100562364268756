.ques-container {
  .ques-body {
    min-height: 220px;
    .question-item {
      display: flex;
    }
    .ques-title {
      display: flex;
      align-items: flex-start;
      line-height: 20px;
      color: #2e3b76;
      .index {
        font-size: 16px;
        font-weight: bold;
        margin-right: 5px;
        display: flex;
        &::after {
          display: inline;
          content: '.';
        }
      }

      .title {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .ques-options {
      display: flex;
      margin: 30px;
      font-size: 16px !important;
    }

    .ques-action {
      margin: 20px;
    }
  }

  .ques-preview {
    margin-left: 20px;
    display: block;

    .right-answ {
      margin-top: 6px;
    }
  }
}
.ant-radio-wrapper,
.ant-checkbox-wrapper {
  font-size: 16px;
}
